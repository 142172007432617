import React from "react";
import { Paper, Alert, Button, Grid, Link } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  Send,
  ScheduleSend,
  CancelScheduleSend,
  Attachment,
  Info,
} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import axios from "axios";
import parse from "html-react-parser";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

function BodyMessage(props) {
  const stylePaper = {
    padding: 20,
  };
  const styleLink = {
    display: "flex",
    alignItems: "center"
  }

  let userCantSendFileError = false;

  let file = null;
  let displayMessage = props.row.message;
  const fileMetaTag = getSendFileMetaTag(props.row.message);
  if (props.row.userCanSendFile) {
    file = fileMetaTag;
    displayMessage = clearMetaTagMessage(wrap(props.row.message));
    if(props.row.link) {
      displayMessage = formatMessage(displayMessage, { link: props.row.link })
    }
  } else if (fileMetaTag) {
    userCantSendFileError = true;
  }

  return (
    <Paper elevation={6} style={stylePaper}>
      {userCantSendFileError && (
        <Alert severity="error">
          O seu plano não permite envio de arquivos!
        </Alert>
      )}
      {parse(displayMessage)}
      {props.row.file && props.row.file.url ? (
        <>
          <br />
          <br />
          <Paper elevation={6} style={stylePaper}>
            <Link
              target="_blank"
              href={props.row.file.url}
              underline="none"
              style={styleLink}
            >
              <Attachment />
              <span style={{ marginLeft: 5, display: "block" }}>
                {props.row.file.url.substring(
                  props.row.file.url.lastIndexOf("/") + 1
                )} {props.row.file.sizeText ? `(${props.row.file.sizeText})` : ''}
              </span>
            </Link>
          </Paper>
        </>
      ) : (
        ""
      )}
      {file && file.url ? (
        <Paper elevation={6} style={stylePaper}>
          <Link
            target="_blank"
            href={file.url}
            underline="none"
            style={styleLink}
          >
            <Attachment />
            <span style={{ marginLeft: 5, display: "block" }}>
              {file.filename ? file.filename : file.url} {file.sizeText ? `(${file.sizeText})` : ''}
            </span>
          </Link>
        </Paper>
      ) : (
        ""
      )}
    </Paper>
  );
}

function SendAt(props) {
  const optionDate = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  return (
    <>
      {props.row.sendAt ? (
        <div>
          <b>Entregue:</b>{" "}
          {new Date(props.row.sendAt).toLocaleDateString("pt-br", optionDate)}{" "}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

function CardMessage(props) {
  const styleAlert = {
    height: "auto",
    lineHeight: "28px",
    padding: 24,
    whiteSpace: "pre-line",
  };

  return (
    <>
      {props.row.status === "success" && (
        <Alert
          iconMapping={{
            success: <Send fontSize="inherit" />,
          }}
          style={styleAlert}
        >
          <SendAt row={props.row} />
          <br />
          <BodyMessage row={props.row} />
        </Alert>
      )}
      {props.row.status === "queue" && (
        <Alert
          severity="info"
          iconMapping={{
            info: <ScheduleSend fontSize="inherit" />,
          }}
          style={styleAlert}
        >
          <BodyMessage row={props.row} />
        </Alert>
      )}
      {props.row.status === "erro" && (
        <Alert
          severity="error"
          iconMapping={{
            error: <CancelScheduleSend fontSize="inherit" />,
          }}
          style={styleAlert}
        >
          {props.row.erro ? (
            <>
              <b>Motivo do Erro:</b> {translate(props.row.erro.text)}
              <HtmlTooltip title={<>{helpInfo(props.row.erro.text)}</>}>
                <Info color="info" />
              </HtmlTooltip>
            </>
          ) : (
            "Motivo não identificado"
          )}
          <BodyMessage row={props.row} />
        </Alert>
      )}
      {props.row.status === "blocked_contact" && (
        <Alert
          severity="error"
          iconMapping={{
            error: <CancelScheduleSend fontSize="inherit" />,
          }}
          style={styleAlert}
        >
          <b>Motivo do Erro:</b> Este número está bloqueado!
          <BodyMessage row={props.row} />
        </Alert>
      )}
    </>
  );
}

function wrap(str) {
  if (str) {
    return str
      .replace(/(?:\*)([^*]*)(?:\*)/gm, "<strong>$1</strong>")
      .replace(/(?:_)([^_]*)(?:_)/gm, "<i>$1</i>")
      .replace(/(?:~)([^~]*)(?:~)/gm, "<strike>$1</strike>")
      .replace(/(?:```)([^```]*)(?:```)/gm, "<tt>$1</tt>");
  } else {
    return str;
  }
}

function getSendFileMetaTag(message) {
  let regexSendFile = "%SendFile%(.+?)%/SendFile%";
  let regexFilename = "%Filename%(.+?)%/Filename%";
  let regexUrl = "%Url%(.+?)%/Url%";
  let regexApp = "%App%(.+?)%/App%";

  let tagSendFile = message.match(regexSendFile);
  let tagFilename;
  let tagUrl;
  let tagApp;

  let filename, app, url, type;

  if (tagSendFile) {
    tagFilename = tagSendFile[0].match(regexFilename);
    tagUrl = tagSendFile[0].match(regexUrl);
    tagApp = tagSendFile[0].match(regexApp);

    if (tagFilename) {
      filename = tagFilename[0].replace("%Filename%", "");
      filename = filename.replace("%/Filename%", "");
    }
    if (tagApp) {
      app = tagApp[0].replace("%App%", "");
      app = app.replace("%/App%", "");
    }
    if (tagUrl) {
      url = tagUrl[0].replace("%Url%", "");
      url = url.replace("%/Url%", "");

      type = url.substring(url.lastIndexOf(".") + 1);

      if ((app && app === "IXC") || (app && app === "MKAUTH")) {
        if (url.substr(-5) === ".html") {
          url = url.substring(0, url.length - 5);
        }
      }
    }
    if (url) {
      return {
        filename: filename,
        url: url,
        type: type,
        app: app,
      };
    }
  }
  return undefined;
}

function clearMetaTagMessage(message) {
  return message.replace(/(%SendFile%)(.*)(%\/SendFile%)/g, "");
}

function clearMetaTagPix(message, value = '') {
  return message.replace(/(%Pix%)((.|\r|\n|\t)*)(%\/Pix%)/g, value)
}

function clearMetaTagPaymentLink(message, value = '') {
  return message.replace(/(%PaymentLink%)((.|\r|\n|\t)*)(%\/PaymentLink%)/g, value)
}

function formatMessage(message, { link = '' } = {}) {
  let msg = clearMetaTagMessage(message)
  msg = clearMetaTagPix(msg, link)
  msg = clearMetaTagPaymentLink(msg, link)

  return msg
}

function translate(text) {
  let auxText = text;
  switch (text) {
    case "The number does not exist":
      auxText = "O número não existe no WhatsApp!";
      break;
    case "Only absolute URLs are supported":
      auxText = "URL de arquivo inválida.";
      break;
    case "Request failed with status code 403":
      auxText = "Houve uma falha de arquivo ou permissão de acesso.";
      break;
    case "Request failed with status code 404":
      auxText = "O arquivo não foi encontrado.";
      break;
    case "Request failed with status code 429":
      auxText = "Você excedeu o limite de solicitações para esse arquivo.";
      break;
    case "Cannot read properties of undefined (reading 'queue')":
      auxText =
        "Não foi possível enviar sua mensagem por conta de um problema de conexão.";
      break;
    case "Protocol error (Page.navigate): Cannot navigate to invalid URL":
      auxText = "URL de arquivo inválida.";
      break;
    case "Número de telefone mal formatado":
      auxText = "Número de telefone mal formatado.";
      break;
    default:
      auxText = text;
      break;
  }
  return auxText;
}

function helpInfo(text) {
  let auxText = text;
  switch (text) {
    case "The number does not exist":
      auxText =
        "Ocorre quando você tenta enviar uma mensagem para um número que não existe no WhatsApp, sendo assim: verifique o número e tente novamente.";
      break;
    case "Only absolute URLs are supported":
      auxText =
        "Ocorre quando você tenta enviar um arquivo com uma URL inválida, sendo assim: verifique a URL e tente novamente.";
      break;
    case "Request failed with status code 403":
      auxText =
        "Ocorre quando existe algum problema com o arquivo ou quando o mesmo tem restrição de acesso, sendo assim: verifique a disponibilidade do arquivo e tente novamente.";
      break;
    case "Request failed with status code 404":
      auxText =
        "Ocorre quando o arquivo não é encontrado, sendo assim: verifique a disponibilidade do arquivo e tente novamente.";
      break;
    case "Request failed with status code 429":
      auxText = "Você excedeu o limite de solicitações para esse arquivo.";
      auxText =
        "Ocorre quando o limite de solicitações do arquivo é excedido, sendo assim: verifique com a plataforma onde o arquivo está localizado.";
      break;
    case "Cannot read properties of undefined (reading 'queue')":
      auxText =
        "Ocorre quando sua instância perde conexão com o WhatsApp utilizado, sendo assim: reinicie sua instância, aguarde 2 minutos. Caso não funcione, expire o QRCODE e conecte novamente.";
      break;
    case "Protocol error (Page.navigate): Cannot navigate to invalid URL":
      auxText =
        "Ocorre quando você tenta enviar um arquivo com uma URL inválida, sendo assim: verifique a URL e tente novamente.";
      break;
    case "Número de telefone mal formatado":
      auxText =
        "Ocorre quando você tenta enviar uma mensagem para um número mal formatado, sendo assim: verifique o número.";
      break;
    default:
      auxText = text;
      break;
  }
  return auxText;
}

export default CardMessage;
